import React from 'react';
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  theme,
} from '@chakra-ui/react';
import { ColorModeSwitcher } from './ColorModeSwitcher';
import { Logo } from './Logo';
import './assets/styles/styles.css';
import Home from './pages/home/Home';
import Registration from './pages/registration/Registration';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import NotFound from './pages/NotFound';

function App() {
  return (
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <Box textAlign="center" fontSize="xl">
          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="/register" element={<Registration />}></Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Box>
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;
