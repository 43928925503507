import {
  Box,
  Button,
  Container,
  Heading,
  HStack,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  VStack,
} from '@chakra-ui/react';
import logo from './../../assets/images/user_logo-min.png';
import AlertPop from '../../components/alertPop/AlertPop';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import axios from 'axios';

const Home = () => {
  const navigate = useNavigate();
  const [error, setError] = useState('');

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = data => {
    console.log(data);

    axios
      .post(
        'https://spotcloud.zone:3000/api/v1/spotcloud-app/submit-invitation-code',
        {
          invitationCode: data.invitationCode,
        }
      )
      .then(function (response) {
        console.log(response);
        navigate('/register', {
          state: { iCode: data.invitationCode, isAuthenticated: true },
        });
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.data.code == 500) {
          setError('Invitation code is invalid');
        }
      });
  };

  return (
    <Box pt={10} minH="100vh" className="hero-gradient">
      <VStack>
        <HStack mr={3}>
          {' '}
          <Image
            borderRadius="full"
            boxSize={['80px', '120px']}
            src={logo}
            alt="logo"
          />
          <Heading fontSize={['3xl', '4xl', '6xl']} color="white">
            Spotcloud
          </Heading>
        </HStack>
        <Text
          pt={6}
          fontSize={['1xl', '1xl', '2xl']}
          maxWidth="xl"
          color="whiteAlpha.800"
        >
          Create Communities & Share Content. <br></br> Made for all
          Organizations. <br></br>
        </Text>

        {/* <Box
          pt={14}
          display="flex"
          flexDirection={['column', 'column', 'row']}
          flexWrap="wrap"
          justifyContent="center"
          alignItems="center"
          alignContent="center"
          maxWidth="xl"
        >
          <Box p={3}>
            <HStack>
              <IconButton
                background="cyan.200"
                fontSize={26}
                aria-label="Mobile"
                icon={<MdPhoneIphone />}
              />
              <Box pl={[2]}>
                <Text
                  textAlign="start"
                  fontWeight={'bold'}
                  color="white"
                  fontSize={18}
                >
                  User Mobile App
                </Text>
                <Text
                  fontSize={['14', '1xl', '1xl']}
                  maxWidth="sm"
                  color="whiteAlpha.800"
                >
                  Android & iOS app for end-users
                </Text>
              </Box>
            </HStack>
          </Box>
          <Box p={3}>
            <HStack>
              <IconButton
                background="cyan.200"
                fontSize={26}
                aria-label="Mobile"
                icon={<MdSettingsRemote />}
              />
              <Box pl={[2]}>
                <Text
                  textAlign="start"
                  fontWeight={'bold'}
                  color="white"
                  fontSize={18}
                >
                  Remote Control App
                </Text>
                <Text
                  fontSize={['14', '1xl', '1xl']}
                  maxWidth="sm"
                  color="whiteAlpha.800"
                >
                  For Creator presentation mode &nbsp;
                </Text>
              </Box>
            </HStack>
          </Box>
          <Box p={3}>
            <HStack>
              <IconButton
                background="cyan.200"
                fontSize={26}
                aria-label="Mobile"
                icon={<MdDashboard />}
              />
              <Box pl={[2]}>
                <Text
                  textAlign="start"
                  fontWeight={'bold'}
                  color="white"
                  fontSize={18}
                >
                  Customer Admin App
                </Text>
                <Text
                  fontSize={['14', '1xl', '1xl']}
                  maxWidth="sm"
                  color="whiteAlpha.800"
                >
                  Dashboard App with all privileges
                </Text>
              </Box>
            </HStack>
          </Box>
          <Box p={3}>
            <HStack>
              <IconButton
                background="cyan.200"
                fontSize={26}
                aria-label="Mobile"
                icon={<RiDatabase2Fill />}
              />
              <Box pl={[2]}>
                <Text
                  textAlign="start"
                  fontWeight={'bold'}
                  color="white"
                  fontSize={18}
                >
                  Content Creator App
                </Text>
                <Text
                  fontSize={['14', '1xl', '1xl']}
                  maxWidth="sm"
                  color="whiteAlpha.800"
                >
                  Web App for content creation &nbsp; &nbsp;
                </Text>
              </Box>
            </HStack>
          </Box>
        </Box> */}
        <Box pt={10} pb={6}>
          <Container>
            <InputGroup size="lg">
              <Input
                size="lg"
                background="white"
                pr="5rem"
                type="text"
                placeholder="Enter Invitation Code"
                {...register('invitationCode', {
                  required: 'Please enter Invitation Code',
                  minLength: { value: 7, message: 'Minimum 7 characters' },
                })}
              />

              <InputRightElement width="4.5rem">
                <Button
                  onClick={handleSubmit(onSubmit)}
                  colorScheme="yellow"
                  variant="solid"
                  size="md"
                  h="2.21rem"
                  boxShadow="md"
                  mr="0.5em"
                >
                  submit
                </Button>
              </InputRightElement>
            </InputGroup>
            {errors.invitationCode && (
              <AlertPop title={errors.invitationCode.message} />
            )}
            {!errors.invitationCode && error.length > 0 && (
              <AlertPop title={error} />
            )}
          </Container>
        </Box>
      </VStack>
    </Box>
  );
};
export default Home;
