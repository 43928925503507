import {
  Box,
  Input,
  useToast,
  VStack,
  Button,
  Container,
  Text,
  Center,
  Flex,
  FormHelperText,
  FormControl,
  InputGroup,
  InputRightElement,
  HStack,
  Icon,
  FormLabel,
} from '@chakra-ui/react';

import { useForm } from 'react-hook-form';
import AlertPop from '../../components/alertPop/AlertPop';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';

const Registration = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const { state } = useLocation();
  const [URLSlug, setURLSlug] = useState('');
  const [URLSlugCheck, setURLSlugCheck] = useState('');

  console.log(state);

  useEffect(() => {
    if (!state) {
      navigate('/');
    }
  }, []);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data, e) => {
    if (URLSlugCheck === 'yes') {
      data['URLSlug'] = URLSlug;
      data['invitationCode'] = state.iCode;
      console.log(data);

      axios
        .post(
          'https://spotcloud.zone:3000/api/v1/spotcloud-app/customer-registration',
          {
            ...data,
          }
        )
        .then(res => {
          console.log(data);
          resetForm();
          toast({
            title: 'Submitted!',
            description: 'please check your email for verfication',
            status: 'success',
            duration: 7210,
            isClosable: true,
            position: 'bottom-right',
            variant: 'top-accent',
          });
        })
        .catch(res => {
          console.log(res);
          toast({
            description: `${res.response.data.message}`,
            title: 'Error',
            status: 'error',
            duration: 3210,
            isClosable: true,
            position: 'bottom-right',
            variant: 'top-accent',
          });
        });
    } else {
      toast({
        description: 'Type unique URL path for your app & click check!',
        title: 'Error',
        status: 'error',
        duration: 3210,
        isClosable: true,
        position: 'bottom-right',
        variant: 'top-accent',
      });
    }
  };

  function resetForm() {
    reset();

    navigate('/');
  }

  const handleURLSlugChange = e => {
    let newURLSlug = e.target.value;

    newURLSlug = newURLSlug.replace(/[^a-zA-Z0-9-]/g, '');

    setURLSlug(newURLSlug);

    axios
      .post('https://spotcloud.zone:3000/api/v1/spotcloud-app/check-url-slug', {
        URLSlug: newURLSlug,
      })
      .then(res => {
        console.log(res.data);
        if (res.data.code === 200) {
          if (res.data.data.isURLSlugValid) {
            setURLSlugCheck('yes');
          } else {
            setURLSlugCheck('no');
          }
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  return (
    <Box minH="100vh">
      <Box pt={10} id="registerForm">
        <Center>
          <Text fontSize={['20', '26', '32']} fontWeight="bold" mb={8}>
            Register & try for 30 days free
          </Text>
        </Center>
        <Container>
          <form onSubmit={handleSubmit(onSubmit)}>
            <VStack pb={4}>
              <Text fontSize="2xl">Admin Details</Text>
              <FormControl isRequired>
                <FormLabel>First name</FormLabel>
                <Input
                  background="white"
                  type="text"
                  placeholder="First name"
                  {...register('firstName', {
                    required: 'Please enter first name',
                    minLength: { value: 3, message: 'Minimum 3 characters' },
                  })}
                />
                {errors.firstName && (
                  <AlertPop title={errors.firstName.message} />
                )}
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Last name</FormLabel>
                <Input
                  background="white"
                  type="text"
                  placeholder="Last name"
                  {...register('lastName', {
                    minLength: { value: 2, message: 'Minimum 2 characters' },
                  })}
                />
                {errors.lastName && (
                  <AlertPop title={errors.lastName.message} />
                )}
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Email address</FormLabel>
                <Input
                  background="white"
                  type="email"
                  placeholder="Email address"
                  {...register('email', {
                    required: 'Please enter email address',
                  })}
                />
                <Flex width="100%">
                  <FormHelperText>
                    Email address will be verified.
                  </FormHelperText>
                </Flex>
                {errors.email && <AlertPop title={errors.email.message} />}
              </FormControl>
              <Text fontSize="2xl">Organization Details</Text>
              <FormControl isRequired>
                <FormLabel>Organization name</FormLabel>
                <Input
                  type="text"
                  placeholder="Organization name"
                  {...register('organizationName', {
                    required: 'Please enter organization name',
                    minLength: { value: 2, message: 'Minimum 2 characters' },
                  })}
                />
                {errors.organizationName && (
                  <AlertPop title={errors.organizationName.message} />
                )}
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Street name</FormLabel>
                <Input
                  type="text"
                  placeholder="Street name"
                  {...register('address.streetName', {
                    required: 'Please enter street name',
                    minLength: { value: 2, message: 'Minimum 2 characters' },
                  })}
                />
                {errors.address?.streetName && (
                  <AlertPop title={errors.address?.streetName.message} />
                )}
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Street number</FormLabel>
                <Input
                  type="text"
                  placeholder="Street number"
                  {...register('address.streetNumber', {
                    required: 'Please enter street number',
                    minLength: { value: 1, message: 'Minimum 1 character' },
                  })}
                />
                {errors.address?.streetNumber && (
                  <AlertPop title={errors.address?.streetNumber.message} />
                )}
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Postal code</FormLabel>
                <Input
                  type="text"
                  placeholder="Postal code"
                  {...register('address.postalCode', {
                    required: 'Please enter postal code',
                    minLength: { value: 5, message: 'Minimum 5 characters' },
                  })}
                />
                {errors.address?.postalCode && (
                  <AlertPop title={errors.address?.postalCode.message} />
                )}
              </FormControl>
              <FormControl isRequired>
                <FormLabel>City</FormLabel>
                <Input
                  type="text"
                  placeholder="City"
                  {...register('address.cityName', {
                    required: 'Please enter city',
                    minLength: { value: 2, message: 'Minimum 2 characters' },
                  })}
                />
                {errors.address?.cityName && (
                  <AlertPop title={errors.address?.cityName.message} />
                )}
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Unique URL Path for Your App</FormLabel>
                <InputGroup size="md">
                  <Input
                    pr="6rem"
                    type="text"
                    placeholder="Unique URL Path for Your App"
                    value={URLSlug}
                    onChange={handleURLSlugChange}
                  />
                </InputGroup>
                <Flex width="100%" flexDirection="row" alignItems="center">
                  <FormHelperText>
                    For ex: "name-of-your-organization"{' '}
                  </FormHelperText>
                  {URLSlugCheck === 'yes' ? (
                    <FormHelperText ml={3} color="green">
                      <HStack>
                        {' '}
                        <Icon as={FaCheckCircle} />
                        <Text>URL path available</Text>
                      </HStack>
                    </FormHelperText>
                  ) : (
                    ''
                  )}
                  {URLSlugCheck === 'no' ? (
                    <FormHelperText ml={3} color="red">
                      <HStack>
                        {' '}
                        <Icon as={FaTimesCircle} />
                        <Text as={'h5'}> URL path not available</Text>
                      </HStack>
                    </FormHelperText>
                  ) : (
                    ''
                  )}
                </Flex>
              </FormControl>
              <Button
                borderRadius="md"
                colorScheme="teal"
                variant="solid"
                type="submit"
                isDisabled={URLSlug.length === 0}
              >
                Submit
              </Button>
            </VStack>
          </form>
        </Container>
      </Box>
    </Box>
  );
};
export default Registration;
